<template>
  <div>
    <component :is="componentName"></component>
  </div>
</template>
<script>
import RepairItemManagementList from "./list";
export default {
  name: "repair-item-management",
  components:{
    RepairItemManagementList
  },
  data(){
    return {
      componentName:'RepairItemManagementList'
    }
  }
}
</script>

<style scoped>

</style>